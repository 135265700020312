import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const FooterNavSectionHead = styled.div`
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: #989898;
    cursor: pointer;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        position: relative;
        font-size: 16px;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -5px;
            border: 5px solid transparent;
            border-top: 5px solid #989898;
        }
    }
`;

export const FooterNavLink = styled(RouterNavLink)`
    display: inline-block;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    font-size: 16px;
    color: #989898;
    cursor: pointer;
    transition: opacity 0.4s;
    text-decoration: none;

    &:hover {
        opacity: 0.6;
    }

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-left: 15px;
        font-size: 12px;
    }
`;

export const FooterNavSectionBody = styled.div`
    display: flex;
    flex-direction: column;
    ${FooterNavLink} {
        margin-bottom: 18px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: ${props => (props.open ? 'flex' : 'none')};
    }
`;

export const FooterNavSection = styled.div`
    ${FooterNavSectionHead} {
        margin-bottom: 18px;
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            margin-bottom: 10px;
        }
    }
`;
export const FooterNavStyled = styled.div`
    display: none;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    ${FooterNavSection} {
        margin-right: 55px;
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            margin-right: 0;
        }
    }
`;
