import styled from 'styled-components';

export const PageStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding: 100px 139px 200px 155px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding: 100px 139px 200px 155px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        padding: 80px 55px 100px 48px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 40px 35px 100px 28px;
    }
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-bottom: 20px;
`;
export const Title = styled.p`
    font-size: 25px;
    color: #707070;
    font-weight: bold;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: center;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 25px;
        padding-bottom: 70px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 25px;
        padding-bottom: 50px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        font-size: 21px;
        padding-bottom: 40px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 21px;
        padding-bottom: 30px;
    }
`;

export const Text = styled.p`
    font-size: 25px;
    color: #707070;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 20px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 20px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        font-size: 12px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 12px;
    }
`;

export const Label = styled.p`
    font-size: 25px;
    color: #707070;
    font-weight: bold;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 20px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 20px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        font-size: 12px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 12px;
    }
`;
