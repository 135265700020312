import React from 'react';
import { Container, TextBlock, ImgBlock, Head, TextInnerBlock, ContactInfo, BgImg } from './styles';
import MetaTags from 'react-meta-tags';

const LocationPage = () => (
    <Container>
        <MetaTags>
            <meta
                name="description"
                content="HT Closings is conveniently located throughout the Chicagoland area. Get directions. View map."
            />
            <meta
                name="keywords"
                content="real,estate,attorney,chicago,cheap,affordable,locations,location,map,directions,address,office,offices,law,legal,lawyer,closing,closings,fast,"
            />
            <meta name="author" content="HTClosings.com" />
            <title>
                HT Closings – Fast and Affordable Real Estate Legal Services Office Locations
            </title>
        </MetaTags>
        <TextBlock>
            <TextInnerBlock>
                <Head>Visit us at</Head>
                <ContactInfo>
                    HT Law LLC
                    <br />
                    444 N. Wabash Ave.
                    <br />
                    Suite #<br />
                    Chicago, IL 60611
                    <br />
                    Phone Number
                    <br />
                    Email Address
                    <br />
                </ContactInfo>
            </TextInnerBlock>
        </TextBlock>
        <ImgBlock>
            <BgImg src={require('./bg.svg')} alt="" />
        </ImgBlock>
    </Container>
);

export default LocationPage;
