import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

export const Button = styled(Link)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100px;
    text-align: center;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    color: #fff;
    background-color: #00edd0;
    border-radius: 3px;
    box-shadow: 9px 20px 40px ${props => props.theme.colors.primaryLight};
    border: none;
    cursor: pointer;
    transition: opacity 0.4s;
    text-decoration: none;
    @media (max-width: ${props => props.theme.media.tablet}px) {
        border-radius: 0;
    }
`;
export const SelectRoleSectionStyled = styled.section`
    padding-top: 120px;
    margin-bottom: -85px;
    position: relative;
    overflow: hidden;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding-top: 90px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-top: 60px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 20px;
        padding-bottom: 35%;
        margin: 0;
    }
`;
export const Content = styled.div`
    text-align: center;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        max-width: 560px;
        margin: 0 auto;
    }
`;
export const Head = styled.h1`
    margin-bottom: 40px;
    color: ${p => p.theme.colors.dark};
    font-family: ${p => p.theme.fonts.main};
    font-weight: 400;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        margin-bottom: 30px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-bottom: 17px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-bottom: 15px;
    }
`;
export const SubHead = styled.h4`
    margin-bottom: 80px;
    color: ${p => p.theme.colors.grey};
    font-family: ${p => p.theme.fonts.main};
    font-weight: 400;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        margin-bottom: 60px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-bottom: 30px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: 225px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
`;
export const WrapBtns = styled.div`
    @media (max-width: ${props => props.theme.media.laptop}px) {
        margin-bottom: 20px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    ${Button} {
        width: 280px;
        height: 55px;
        margin: 0 20px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        @media (max-width: ${props => props.theme.media.fullHD}px) {
            width: 240px;
            height: 48px;
        }
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            width: 180px;
            height: 35px;
            margin: 0 5px;
        }
        @media (max-width: ${props => props.theme.media.laptop}px) {
            width: 135px;
            height: 30px;
            margin: 0 4px;
        }
        @media (max-width: ${props => props.theme.media.tablet}px) {
            width: 123px;
            height: 24px;
        }
    }
`;
export const BgImg = styled.img`
    position: relative;
    top: -80px;
    z-index: -1;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 100%;
        top: -60px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: none;
    }
`;
export const BgMobileImg = styled.img`
    display: none;
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: block;
        position: relative;
        z-index: -1;
        width: 200%;
        position: absolute;
        left: -45%;
        bottom: 30px;
    }
`;
