import styled from 'styled-components';

export const WrapHeads = styled.div`
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        max-width: 575px;
        margin: 0 auto;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        max-width: 425px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: 525px;
    }
`;
export const BuyingSellingSectionStyled = styled.section`
    position: relative;
    padding-bottom: 550px;
    overflow: hidden;
    margin-top: -140px;
    background-color: ${props => props.theme.colors.whiteDirty};
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding-bottom: 450px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-top: 20px;
        padding-bottom: 290px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        padding-bottom: 200px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-bottom: 160px;
    }
`;
export const Container = styled.div`
    width: 100%;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    max-width: 860px;
    padding: 20px;
    box-sizing: border-box;
`;
export const SectionSubHead = styled.h2`
    margin-bottom: 70px;
    margin-top: 10px;   
    color: ${props => props.theme.colors.primary};
    font-weight: 300;
    font-size: 40px;
    line-height: 1.2;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: right;

    @media (max-width: ${props => props.theme.media.laptop}px) {
        margin-bottom: 20px;
        padding-right: 80px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-top: 10px;
        margin-right: 13px;
        margin-bottom: 15px;
        padding-right: 0;
        text-align: center;
    }
`;
export const Text = styled.p`
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 30px;
    color: ${props => props.theme.colors.dark};
    font-size: 20px;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: center;
`;

export const BgImg = styled.img`
    width: 1600px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 1000px;
        bottom: 0;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 740px;
        right: 0;
        bottom: 0;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 500px;
        right: 0;
        bottom: 0;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 400px;
        right: 0;
        bottom: 0;
    }
`;
