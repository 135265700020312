import styled from 'styled-components';

export const IntroducingSectionStyled = styled.section`
    padding-top: 65px;
    overflow: hidden;

    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-top: 23px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-top: 40px;
    }
`;
export const SectionHead = styled.h1`
    font-size: 40px;
    line-height: 1.2;
    color: #4b4b4b;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: center;
    padding: 0 20px;

    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-bottom: 5px;
    }
`;
export const SectionSubHead = styled.h2`
    margin-bottom: 10px;
    color: #00cfb2;
    font-weight: 300;
    font-size: 40px;
    line-height: 1.2;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: center;
    padding: 0 20px;
`;
export const Title = styled.p`
    margin: 0;
    color: ${props => props.theme.colors.grey};
    font-size: 20px;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: center;
    font-weight: 300;
    padding: 0 20px;
    
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-bottom: -40px;
    }
`;

export const BgImg = styled.img`
    display: block;
    width: 100%;
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: none;
    }
`;
export const BgMobile = styled.img`
    display: none;
    width: 100%;
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: block;
        width: 1300px;
        margin-left: -59px;
        margin-bottom: -80px;
        position: relative;
        z-index: -1;
    }
`;
