import styled from 'styled-components';

export const FaqPageStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 165px;
    padding-bottom: 120px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding-right: 140px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-right: 0;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        padding: 0 80px;
        margin-bottom: 80px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: block;
        padding: 40px 44px;
        margin-bottom: 0;
    }
`;

export const AccordionHead = styled.h3`
    font-weight: 400;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.main};
`;
export const Head = styled.h1`
    font-family: ${p => p.theme.fonts.main};
    font-weight: 400;
    color: ${p => p.theme.colors.grey};
    margin-top: 81px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        margin-top: 49px;
    };
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-top: 0px;
        padding-left: 470px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        max-width: 255px;
        padding-left: 320px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-left: 0;
    }
`;
export const Contact = styled.div`
    color: ${p => p.theme.colors.grey};
    font-family: ${p => p.theme.fonts.main};
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-left: 470px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        padding-left: 320px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-left: 0;
    }
`;
export const Email = styled.div`
    color: ${p => p.theme.colors.dark};
`;
export const BgMobileImg = styled.img`
    display: none;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: block;
        width: 190px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        max-width: 165px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: 76px;
    }
`;

export const Content = styled.div`
    max-width: 620px;
    padding-top: 60px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        max-width: 515px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        max-width: 860px;
        margin: 0 auto;
        padding-top: 120px;
        position: relative;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        position: relative;
        padding-top: 0;
    }

    ${Contact} {
        margin-bottom: 60px;
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            margin-bottom: 160px;
        }
        @media (max-width: ${props => props.theme.media.tablet}px) {
            max-width: 170px;
            margin-bottom: 80px;
        }
    }
    ${BgMobileImg} {
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            position: absolute;
            top: 45px;
            left: 100px;
        }
        @media (max-width: ${props => props.theme.media.laptop}px) {
            left: 35px;
        }
        @media (max-width: ${props => props.theme.media.tablet}px) {
            top: 0;
            left: initial;
            right: -15px;
        }
        @media (max-width: 370px) {
            display: none;
        }
    }
`;

export const BgImg = styled.img`
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 800px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: none;
    }
`;
