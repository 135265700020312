export const colors = {
    black: '#000000',
    white: '#ffffff',
    gray: '#cdcdcd',
    grayDark: '#8b8b8b',
    red: '#d72000',
    redPink: '#e21114',
    pinkRed: '#ff7272', // also known as "Bittersweet" according to chir.ag
    pinkLight: '#f3f3f3',
    green: '#62b120'
};

export const breakpoints = {
    xl: '1200px',
    lg: '992px',
    md: '768px',
    sm: '576px'
};

export const transitions = {
    standart: 'all 0.35s ease-out'
};
