import React from 'react';
import { SectionHead } from './../../../../ui/SectionHead';
import {
    HowItWorksSectionStyled,
    Steps,
    Step,
    Leptop,
    Rocket,
    Wagon,
    Number,
    StepTitle
} from './styles';

const HowItWorksSection = () => (
    <HowItWorksSectionStyled>
        <SectionHead>How It Works</SectionHead>
        <Steps>
            <Step>
                <Leptop src={require('./laptop.svg')} alt="" />
                <Number>1</Number>
                <StepTitle>Get in touch with us</StepTitle>
            </Step>
            <Step>
                <Rocket src={require('./rocket.svg')} alt="" />
                <Number className="num-2">2</Number>
                <StepTitle>Streamline your closing</StepTitle>
            </Step>
            <Step>
                <Wagon src={require('./wagon.svg')} alt="" />
                <Number>3</Number>
                <StepTitle>Move in/out!</StepTitle>
            </Step>
        </Steps>
    </HowItWorksSectionStyled>
);

export default HowItWorksSection;
