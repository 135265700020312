import React from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import {
    ContactPageStyled,
    Head,
    ContactInfo,
    Form,
    FormHead,
    Content,
    Container,
    Line,
    BgImg,
    CounterCharacters,
    CountCharacters,
    MaxCharacters,
    ContentTop,
    ContentTopAddress,
    ContentTopImg,
    BgImgMob,
    SubmitButton
} from './styles';
import { FormControl, Input, LabelInput, Textarea } from 'ui';
import SubmitPopup from './SubmitPopup';
import validate from './validation';

const maxCountCharacters = 2000;

class ContactPage extends React.Component {
    state = {
        form: {
            fullName: '',
            email: '',
            phone: '',
            message: '',
            buttonText: 'Send'
        },
        submitSuccess: false,
        submitErrors: null
    };

    dismissPopup = () => this.setState({ submitSuccess: false, submitErrors: null });

    handleInput = ({ target: { name, value } }) => {
        if (name === 'message' && value > maxCountCharacters) {
            value = value.substring(0, maxCountCharacters);
        }

        this.setState(state => ({
            form: {
                ...state.form,
                [name]: value
            }
        }));
    };

    submitContactUs = e => {
        e.preventDefault();
        this.setState({ submitSuccess: false });

        const { form } = this.state;
        const validation = validate(form);

        if (validation.isValid) {
            const formData = new FormData();
            formData.append('fullName', form.fullName);
            formData.append('email', form.email);
            formData.append('phone', form.phone);
            formData.append('message', form.message);

            axios
                .post('https://www.xchangeplus.com/econtact/submit_query', formData)
                .then(res => {
                    const {
                        data: { success, message }
                    } = res;
                    if (success !== 1) throw Error(message || `Couldn't submit form`);
                    this.setState({
                        submitSuccess: true, 
                        submitErrors: null,
                        form: {
                            fullName: '',
                            email: '',
                            phone: '',
                            message: '',
                            buttonText: 'Send'
                        },
                    });
                })
                .catch(err => {
                    console.warn(err.message);
                });
        } else {
            this.setState({ submitErrors: validation.errors });
        }
    };

    getErrorsText = submitErrors => {
        const errors = Object.keys(submitErrors);

        return (
            <>
                Please enter a valid{' '}
                {errors.map((item, idx) => {
                    if (idx === 0) return <strong key={idx}>{item}</strong>;

                    return (
                        <span key={idx}>
                            {' '}
                            and <strong>{item}</strong>
                        </span>
                    );
                })}
            </>
        );
    };

    render() {
        const { form, submitSuccess, submitErrors } = this.state;
        const formHasErrors = Boolean(submitErrors);
        let errorList = null;
        if (formHasErrors) errorList = this.getErrorsText(submitErrors);

        const popupProps = {
            show: submitSuccess || submitErrors,
            title: submitSuccess ? 'Thank you!' : 'Error!',
            text: submitSuccess
                ? 'Our attorneys will get back to you as soon as possible.'
                : errorList,
            error: formHasErrors
        };

        return (
            <ContactPageStyled>
                <MetaTags>
                    <meta
                        name="description"
                        content="Get in touch with our attorneys at HT Closings and get a head start on your real estate transaction!"
                    />
                    <meta
                        name="keywords"
                        content="real,estate,attorney,chicago,cheap,affordable,contact,law,legal,lawyer,closing,closings,fast,"
                    />
                    <meta name="author" content="HTClosings.com" />
                    <title>
                        HT Closings – Contact Us for Fast and Affordable Real Estate Legal Services
                    </title>
                </MetaTags>
                <Container>
                    <BgImg src={require('./bg.svg')} alt="" />
                    <Content>
                        <ContentTop>
                            <ContentTopAddress>
                                <Head>Get in touch.</Head>
                                <ContactInfo>
                                    HT Law LLC <br />
                                    123456 Street, Suite 00 City, State, Zip Code <br />
                                    Phone Number <br />
                                    Email Address
                                </ContactInfo>
                            </ContentTopAddress>
                            <ContentTopImg>
                                <BgImgMob src={require('./bg.svg')} alt="" />
                            </ContentTopImg>
                        </ContentTop>
                        <Line />
                        <FormHead>
                            Or leave us your information, and we will get back to you.
                        </FormHead>
                        <Form>
                            <FormControl>
                                <LabelInput>Full Name:</LabelInput>
                                <Input
                                    type="text"
                                    name="fullName"
                                    placeholder="First Name, Last Name"
                                    value={form.fullName}
                                    onChange={this.handleInput}
                                />
                            </FormControl>
                            <FormControl>
                                <LabelInput>Email Address:</LabelInput>
                                <Input
                                    type="text"
                                    name="email"
                                    placeholder="Example@mail.com"
                                    value={form.email}
                                    onChange={this.handleInput}
                                />
                            </FormControl>
                            <FormControl>
                                <LabelInput>Phone Number:</LabelInput>
                                <InputMask
                                    mask="(999)-999-9999"
                                    value={form.phone}
                                    onChange={this.handleInput}>
                                    {inputProps => (
                                        <Input
                                            type="text"
                                            name="phone"
                                            placeholder="(###)- ###- ####"
                                        />
                                    )}
                                </InputMask>
                            </FormControl>
                            <FormControl style={{ display: 'block' }}>
                                <LabelInput title="(optional)">Message:</LabelInput>
                                <Textarea
                                    type="text"
                                    name="message"
                                    placeholder="Insert text here"
                                    value={form.message}
                                    onChange={this.handleInput}
                                />
                                <br />
                                <CounterCharacters>
                                    <CountCharacters>{form.message.length}</CountCharacters>/
                                    <MaxCharacters>{maxCountCharacters} characters</MaxCharacters>
                                </CounterCharacters>
                            </FormControl>
                            <SubmitButton type="submit" onClick={this.submitContactUs}>
                                {form.buttonText}
                            </SubmitButton>
                        </Form>
                    </Content>
                </Container>
                <SubmitPopup show={submitSuccess} onDismiss={this.dismissPopup} {...popupProps} />
            </ContactPageStyled>
        );
    }
}

export default ContactPage;
