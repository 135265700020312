import React from 'react';
import { SectionHead } from 'ui/SectionHead';
import {
    BuyingSellingSectionStyled,
    Container,
    Text,
    BgImg,
    SectionSubHead,
    WrapHeads
} from './styles';

const BuyingSellingSection = () => (
    <BuyingSellingSectionStyled>
        <Container>
            <WrapHeads>
                <SectionHead>Buying or Selling Real Estate? Need an Attorney?</SectionHead>
                <SectionSubHead>Look No Further.</SectionSubHead>
            </WrapHeads>
            <Text>
                We use the most up-to-date technology to streamline our practice and save you time
                and money. And who doesn’t want more $$$ for their house?
            </Text>
        </Container>
        <BgImg src={require('./bg.svg')} alt="" />
    </BuyingSellingSectionStyled>
);

export default BuyingSellingSection;
