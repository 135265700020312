import React from 'react';
import { NavLink } from 'react-router-dom';
import { Wrapper, LogoImg } from './styles';

const SideBar = () => {
    return (
        <Wrapper>
            <div className="side-bar">
                <NavLink to="/">
                    <div className="side-bar-logo">
                        <LogoImg src={require('./logo.svg')} alt="logo" />
                    </div>
                </NavLink>
                <section className="contact-us">
                    <a href="mailto:sales@xchangeplus.com" className="contact-us-bottom-text">
                        <p className="mobile-text">Contact Us</p>
                        <span className="arrow">⟶</span>
                    </a>
                </section>
            </div>
        </Wrapper>
    );
};

export default SideBar;
