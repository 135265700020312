import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { InputContainer } from './styles';
import { Label, Error, Hint } from '../FormItemElements';
import Icon from '../Icon';

const Input = React.memo(props => {
    const {
        className,
        value: valueProp,
        defaultValue = '',
        disabled,
        label,
        error,
        hint,
        success,
        block,
        onChange,
        onFocus,
        onBlur,
        ...rest
    } = props;
    const [value, setValue] = useState(defaultValue);
    const [autofill, setAutofill] = useState(false);
    const [focus, setFocus] = useState(false);

    const handleChange = e => {
        const { value } = e.target;
        setValue(value);
        if (onChange) onChange(e);
    };

    const handleFocus = e => {
        setFocus(true);
        if (onFocus) onFocus(e);
    };

    const handleBlur = e => {
        setFocus(false);
        if (onBlur) onBlur(e);
    };

    const handleAutofill = e => {
        const isAutofill = e.animationName === 'onAutoFillStart';
        if (autofill !== isAutofill) setAutofill(isAutofill);
    };

    useEffect(() => {
        if (valueProp && valueProp !== value) {
            setValue(valueProp);
        }
    }, [value, valueProp]);

    const labelRaised = Boolean(value) || focus || autofill;

    return (
        <InputContainer className={clsx('input', className, { focus, block, disabled, error })}>
            <input
                value={value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onAnimationStart={handleAutofill}
                disabled={disabled}
                {...rest}
            />
            <Label className={clsx({ raised: labelRaised })}>{label}</Label>
            <Error>{error}</Error>
            {hint && <Hint>{hint}</Hint>}
            {success && <Icon name="check" className="success-icon" />}
        </InputContainer>
    );
});

export const InputAdapter = props => {
    const { meta, input, ...rest } = props;
    return <Input {...input} {...rest} success={meta.valid} error={meta.touched && meta.error} />;
};

export default Input;
