import styled from 'styled-components';

export const InfoRolesSectionStyled = styled.section``;

export const Price = styled.img`
    width: 375px;
    height: 100px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 312px;
        height: 82px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 140px;
        height: 37px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 106px;
        height: 28px;
    }
`;

export const RoleInfoServicesItem = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: ${p => p.theme.colors.grey};
    font-family: ${p => p.theme.fonts.main};
    &::before {
        content: '';
        display: block;
        min-width: 30px;
        min-height: 30px;
        margin-right: 15px;
        background: url(${require('./star.png')}) no-repeat center center;
        background-size: contain;
    }
    @media (max-width: ${props => props.theme.media.fullHD}px) {
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        &::before {
            min-width: 15px;
            min-height: 15px;
        }
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-bottom: 10px;
    }
`;
export const RoleInfoServicesHead = styled.h3`
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.main};
    font-weight: 400;
`;
export const RoleInfoServices = styled.div`
    max-width: 650px;
    margin-bottom: 25px;
    ${RoleInfoServicesItem} {
        margin-bottom: 15px;
    }
    ${RoleInfoServicesHead} {
        margin-bottom: 25px;
    }
`;
export const RoleInfoDescriptionText = styled.p`
    color: ${p => p.theme.colors.grey};
    font-family: ${p => p.theme.fonts.main};
`;
export const RoleInfoDescriptionHead = styled.h3`
    color: ${p => p.theme.colors.dark};
    font-family: ${p => p.theme.fonts.main};
    font-weight: 400;
`;
export const RoleInfoDescription = styled.div`
    max-width: 820px;
    padding-right: 180px;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        max-width: 690px;
        padding-right: 0;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-right: 0;
        margin-bottom: 40px;
    }
`;
export const RoleInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding: 0 100px;
    padding-top: 150px;
    padding-bottom: 85px;

    &:nth-child(odd) {
        background-color: #f7f7f7;
    }
    &:nth-child(even) {
        background-color: #fff;
    }
    ${Price} {
        position: absolute;
        top: 30px;
        right: 0;
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            top: 25px;
        }
        @media (max-width: ${props => props.theme.media.tablet}px) {
            top: 20px;
        }
    }
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding-top: 120px;
        padding-bottom: 80px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        flex-direction: column;
        padding-top: 75px;
        padding-bottom: 120px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        flex-direction: column;
        padding: 55px 25px;
    }
`;
