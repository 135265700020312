import styled from 'styled-components';

export const Wrapper = styled.div`
    font-family: InterUI;
    .side-bar {
        background-color: #64bdff;
        position: fixed;
        left: 0;
        top: 0;
        width: 298px;
        height: 100%;
        padding: 3rem 3rem 5rem 3rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .side-bar-logo {
        width: 125px;
        height: 75px;
    }
    @media (max-width: 1023px) {
        .side-bar-logo {
            width: 14rem;
        }
    }
    .side-bar-logo svg {
        height: 100%;
        width: 100%;
    }
    .side-bar-logo svg path:not(:first-child) {
        fill: white;
    }
    .side-bar .contact-us {
        color: #fff;
        width: 250px;
    }
    @media (max-width: 1439px) {
        .side-bar .contact-us {
            width: 163px;
        }
    }
    @media (max-width: 1023px) {
        .side-bar .contact-us {
            right: 33px;
            left: initial;
            top: 38px;
            width: 305px;
        }
    }
    @media (max-width: 767px) {
        .side-bar .contact-us {
            width: 100%;
            text-align: right;
        }
    }
    .side-bar .contact-us-title {
        font-size: 22px;
        line-height: 36px;
        display: block;
        padding-bottom: 8px;
        font-weight: 400;
    }
    @media (max-width: 1023px) {
        .side-bar .contact-us-title {
            font-size: 15px;
            line-height: 23px;
            width: 150px;
            display: inline-block;
            vertical-align: top;
            padding-bottom: 0;
        }
    }
    @media (max-width: 767px) {
        .side-bar .contact-us-title {
            display: none;
        }
    }
    .side-bar .contact-us-bottom-text {
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
        display: block;
        width: 176px;
        transition: color 0.5s;
        text-decoration: none;
    }
    @media (max-width: 1439px) {
        .side-bar .contact-us-bottom-text {
            width: 135px;
        }
    }
    @media (max-width: 1023px) {
        .side-bar .contact-us-bottom-text {
            width: 140px;
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
        }
    }
    @media (max-width: 767px) {
        .side-bar .contact-us-bottom-text {
            width: 100%;
            margin-left: 0;
            text-align: right;
        }
    }
    .side-bar .contact-us-bottom-text .text {
        display: inline;
        margin: 0;
    }
    @media (max-width: 767px) {
        .side-bar .contact-us-bottom-text .text {
            display: none;
        }
    }
    .side-bar .contact-us-bottom-text .mobile-text {
        display: none;
    }
    @media (max-width: 767px) {
        .side-bar .contact-us-bottom-text .mobile-text {
            display: inline;
            font-size: 18px;
        }
    }
    .side-bar .contact-us-bottom-text .arrow {
        display: none;
    }
    @media (max-width: 767px) {
        .side-bar .contact-us-bottom-text .arrow {
            color: #fff;
            margin-left: 5px;
            display: inline;
        }
    }
    @media (max-width: 1439px) {
        .side-bar {
            width: 312px;
        }
    }
    @media (max-width: 1023px) {
        .side-bar {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            height: auto;
            flex-direction: row;
            padding: 3rem;
        }
    }
    @media (max-width: 767px) {
        .side-bar {
            padding: 10px;
        }
    }
`;

export const LogoImg = styled.img``;
