import React from 'react';
import { Button } from 'ui/Button';
import { SectionHead } from 'ui/SectionHead';
import { PriceSectionStyled, SubHead, Price, BgImg } from './styles';

const PriceSection = () => (
    <PriceSectionStyled>
        <BgImg src={require('./bg.svg')} alt="" />
        <SectionHead>Close your transaction for the best price.</SectionHead>
        <SubHead>
            For a <span>FLAT FEE</span> of
        </SubHead>
        <Price>
            $299<span>.00</span>
        </Price>
        <Button to="/services">Learn More</Button>
    </PriceSectionStyled>
);

export default PriceSection;
