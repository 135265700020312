import React from 'react';
import { PageStyled, Title, Container, Text } from './styles';

const PolicyPage = () => (
    <PageStyled>
        <Title>Attorney Advertising</Title>
        <Container>
            <Text>
                Some of this site’s content is considered Attorney Advertising under the applicable
                rules of certain states. Prior results do not guarantee a similar outcome.
            </Text>
        </Container>
    </PageStyled>
);

export default PolicyPage;
