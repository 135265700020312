export const validate = values => {
    const errors = {};

    // if (!values.email) {
    // 	errors.email = 'Required';
    // } else if (!isEmail(values.email)) {
    // 	errors.email = 'Email is invalid';
    // }
    if (!values.password) errors.password = 'Required';
    if (!values.passwordConfirm) errors.passwordConfirm = 'Required';
    if (values.passwordConfirm && values.password !== values.passwordConfirm) {
        errors.password = true;
        errors.passwordConfirm = `Passwords don't match`;
    }
    // if (!values.first_name) errors.first_name = 'Required';
    // if (!values.last_name) errors.last_name = 'Required';
    // if (!values.company) errors.company = 'Required';
    // if (!values.type_specific) errors.type_specific = 'Required';
    if (!values.tos) errors.tos = 'Required';

    return errors;
};
