import React from 'react';
import { PageStyled, Title, Container, Label, Text } from './styles';

const PolicyPage = () => (
    <PageStyled>
        <Title>Legal Disclaimer</Title>
        <Container>
            <Text>
                This website and its materials have been prepared by HT Law LLC for
                informational purposes only and do not constitute legal advice. Receipt of this
                information does not create an attorney-client relationship. Do not act upon this
                information without seeking professional counsel. Do not send us confidential
                information until you receive authorization from one of our attorneys to send such
                information to us.
            </Text>
        </Container>
        <Container>
            <Label>Limitation of Liability.</Label>
            <Text>
                Your use of this website is conducted at your own risk. The materials provided on
                this site may not reflect the most accurate and up-to-date legal developments and
                does not constitute legal advice or a substitute for legal counsel. This website and
                its materials may be updated without notice. HT Law LLC is not
                responsible for any errors or omissions in the materials of this site or for damages
                arising from the use of this site under any circumstances.
            </Text>
        </Container>
        <Container>
            <Label>Intellectual Property.</Label>
            <Text>
                The materials on this website provided by HT Law LLC or its
                licensors are the property of HT Law and its licensors and are protected in the U.S.
                and internationally under trademark, copyright, and other intellectual property
                laws. By entering this website, you acknowledge and agree that any name, logo,
                trademark, or service mark contained on this website and all HT Law content is
                owned by HT Law and may not be used by you without prior written approval.{' '}
            </Text>
        </Container>
        <Container>
            <Label>If you have any questions or concerns, please contact us.</Label>
        </Container>
    </PageStyled>
);

export default PolicyPage;
