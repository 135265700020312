import React from 'react';
import './Button.css';
import classNames from 'classnames';

const Button = props => {
    const { children, className, disabled, tag, innerRef, border, white, ...rest } = props;
    const Component = tag || 'button';

    return (
        <Component
            disabled={disabled}
            ref={innerRef}
            className={classNames('button simple', className, { disabled, border, white })}
            {...rest}>
            {children}
        </Component>
    );
};

export default React.memo(Button);
