import React from 'react';
import {
    SelectRoleSectionStyled,
    Content,
    Head,
    SubHead,
    WrapBtns,
    BgImg,
    Button,
    BgMobileImg
} from './styles';

const SelectRoleSection = () => (
    <SelectRoleSectionStyled>
        <Content>
            <Head>Fast-track your real estate transaction!</Head>
            <SubHead>Select your role below to see what services we can offer you.</SubHead>
            <WrapBtns>
                <Button to="#buyers">Buyer</Button>
                <Button to="#sellers">Seller</Button>
            </WrapBtns>
        </Content>
        <BgImg src={require('./bg.svg')} alt="" />
        <BgMobileImg src={require('./bg-mobile.svg')} alt="" />
    </SelectRoleSectionStyled>
);

export default SelectRoleSection;
