import styled from 'styled-components';

export const AboutSectionStyled = styled.section`
    height: 670px;
    position: relative;
    background-color: #fdfdfd;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        background-color: transparent;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        height: 380px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        height: 325px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-top: 220px;
        padding-bottom: 0;
        height: auto;

        &::before {
            top: 205px;
            bottom: initial;
        }
    }
`;

export const Container = styled.div`
    position: relative;
    height: 100%;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 1500px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 100%;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: 820px;
    }
`;

export const BgImg = styled.img`
    display: block;
    position: absolute;
    top: -55px;
    left: -150px;

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 1200px;
        top: -46px;
        left: -120px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 1000px;
        top: -32px;
        left: -150px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin: 0;
        width: 700px;
        top: -252px;
        left: -75px;
    }
`;

export const AirshipImg = styled.img`
    position: absolute;
    right: -160px;
    bottom: -330px;
    width: 500px;

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 400px;
        bottom: -230px;
        right: -90px;
    }

    @media (max-width: ${props => props.theme.media.laptop}px) {
        bottom: -200px;
        right: -80px;
        width: 300px;
    }

    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: none;
    }
`;

export const TextWrapper = styled.div`
    width: 1400px;
    margin: 0 auto;
    position: relative;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 1170px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: auto;
        max-width: 820px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: initial;
    }
`;

export const Text = styled.div`
    max-width: 440px;
    position: absolute;
    top: 270px;
    right: 0px;
    color: ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    font-size: 20px;
    text-align: center;
    z-index: 5;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        max-width: 360px;
        top: 70px;
    }
    @media (max-width: 1280px) {
        max-width: 360px;
        top: 70px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        max-width: 315px;
        top: 70px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: 100%;
        background-color: #fff;
        padding: 20px 8%;
        position: static;
        text-align: center;
        font-style: normal;
        font-weight: 400;
    }
`;
