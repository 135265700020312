import React from 'react';
import { withRouter } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import StringParser from '../../components/StringParser';
import { InputAdapter } from '../../components/Input';
import createDecorator from 'final-form-focus';
import api from '../../services/api';
import { Container, Title } from './style';

const focusOnError = createDecorator();

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Required';
    } else if (!isEmail(values.email)) {
        errors.email = 'Email is invalid';
    }

    return errors;
};

const ForgotPassword = props => {
    const submitForm = async data => {
        const { history } = props;

        const formData = new FormData();

        for (const key in data) {
            formData.append(key, data[key]);
        }

        try {
            const res = await api.post('/password/send', formData);
            const { login_errors } = res.data;

            if (login_errors && login_errors !== '0') {
                throw Error(login_errors);
            }

            alert('Confirmation email has been sent, check your inbox');
            history.push('/login');
        } catch (err) {
            return { [FORM_ERROR]: err.message };
        }
    };

    return (
        <Container>
            <div className="auth-forgot-password">
                <Title>Forgot Password</Title>
                <p className="text">
                    Submit your email address below and we will send a link shortly to reset your
                    password.
                </p>

                <Form
                    decorators={[focusOnError]}
                    validate={validate}
                    onSubmit={submitForm}
                    render={form => (
                        <form onSubmit={form.handleSubmit}>
                            <Field label="Email Address" name="email" component={InputAdapter} />
                            <StringParser className="text-error">{form.submitError}</StringParser>
                            <Button type="submit" disabled={form.submitting}>
                                Submit
                            </Button>
                        </form>
                    )}
                />

                <p>
                    Changed your mind?{' '}
                    <Link to="/login" className="text-link-blue">
                        Back to Login
                    </Link>
                </p>
            </div>
        </Container>
    );
};

export default withRouter(ForgotPassword);
