import React from 'react';
import { ServicesPagetyled } from './styles';
import SelectRoleSection from './sections/SelectRoleSection';
import InfoRolesSection from './sections/InfoRolesSection';
import MetaTags from 'react-meta-tags';

const ServicesPage = () => (
    <ServicesPagetyled>
        <MetaTags>
            <meta
                name="description"
                content="Take a look at the real estate attorney services we offer buyers and sellers in the Chicagoland area to fast-track their transactions."
            />
            <meta
                name="keywords"
                content="real,estate,attorney,chicago,cheap,affordable,services,service,law,legal,lawyer,closing,closings,fast,"
            />
            <meta name="author" content="HTClosings.com" />
            <title>HT Closings - Fast and Affordable Real Estate Attorney Services</title>
        </MetaTags>
        <SelectRoleSection />
        <InfoRolesSection />
    </ServicesPagetyled>
);

export default ServicesPage;
