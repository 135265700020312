import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import './style.css';

const StringParser = props => {
    const { children, ...rest } = props;
    const jsx = children ? parse(children) : null;

    return <div {...rest}>{jsx}</div>;
};

StringParser.propTypes = {
    children: PropTypes.string
};

export default React.memo(StringParser);
