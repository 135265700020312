import styled from 'styled-components';

export const LabelInput = styled.label`
    position: relative;
    padding-bottom: ${p => (p.title ? '15px' : '0')}
    font-family: ${props => props.theme.fonts.main};
    color: ${props => props.theme.colors.grey};
    font-size: 23px;
    &::after {
        content: '${p => p.title}';
        display: ${p => (p.title ? 'block' : 'none')};
        position: absolute;
        bottom: 0;
        right: 0;
        margin-top: 4px;
        font-family: ${p => p.theme.fonts.main};
        color: #FC6161;
        font-size: 13px;
        @media (max-width: ${props => props.theme.media.tablet}px) {
            font-size: 10px;
        };
    };
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 18px;
    };
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 12px;
    };
`;
