import styled from 'styled-components';

export const Textarea = styled.textarea`
    width: 430px;
    height: 120px;
    padding: 7px;
    font-family: ${p => p.theme.fonts.main};
    font-size: 23px;
    border: 0.5px solid ${p => p.theme.colors.grey};
    outline: none;
    resize: none;
    box-sizing: border-box;
    &::placeholder {
        color: #c1c0c0;
    }
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 19px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 180px;
        height: 190px;
        font-size: 14px;
    }
`;
