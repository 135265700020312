import React, {useEffect} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import HomePage from './../../pages/HomePage';
import AboutUsPage from './../../pages/AboutUsPage';
import ContactPage from './../../pages/ContactPage';
import ServicesPage from './../../pages/ServicesPage';
import FaqPage from './../../pages/FaqPage';
import PolicyPage from './../../pages/PolicyPage';
import AttorneyPage from './../../pages/AttorneyPage';
import LegalPage from './../../pages/LegalPage';
import LocationPage from './../../pages/LocationPage';
import MainLayout from '../../layouts/Main';


const Routes = ({location: {pathname}}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<Switch>
			<Route path="/" exact render={() => <MainLayout><HomePage /></MainLayout>}/>
			<Route path="/about-us" exact render={() => <MainLayout><AboutUsPage/></MainLayout> }/>
			<Route path="/contact" exact render={() => <MainLayout><ContactPage/></MainLayout> }/>
			<Route path="/services" exact render={() => <MainLayout><ServicesPage/></MainLayout>}/>
			<Route path="/faq" exact render={() => <MainLayout><FaqPage/></MainLayout>}/>
			<Route path="/policy" exact render={() => <MainLayout><PolicyPage/></MainLayout>}/>
			<Route path="/attorney" exact render={() => <MainLayout><AttorneyPage/></MainLayout>}/>
			<Route path="/legal" exact render={() => <MainLayout><LegalPage/></MainLayout>}/>
			<Route path="/location" exact render={() => <MainLayout><LocationPage/></MainLayout>}/>
		</Switch>
	);
};

export default withRouter(Routes);
