import React from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import SignUpPage from './../../pages/SignUpPage';
import SignInPage from './../../pages/SignInPage';
import ResetPassword from './../../pages/ResetPassword';
import ForgotPassword from './../../pages/ForgotPassword';
import AuthLayout from '../../layouts/Auth';


const Routes = () => {

	return (
		<Switch>
			<Route path="/register/:token?" render={() => <AuthLayout><SignUpPage /></AuthLayout>} />
			<Route path="/login" render={() => <AuthLayout><SignInPage /></AuthLayout>} />
			<Route path="/reset_password" render={() => <AuthLayout><ResetPassword /></AuthLayout>} />
			<Route path="/forgot_password" render={() => <AuthLayout><ForgotPassword /></AuthLayout>} />
		</Switch>
	);
};

export default withRouter(Routes);
