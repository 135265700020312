import React from 'react';
import { ProvideSectionStyled, SectionHead, WrapText, BgImg } from './styles';
import { Text } from './../WhyUsSection/styles';

const ProvideSection = () => (
    <ProvideSectionStyled>
        <SectionHead>We provide a specialized experience that other firms just don’t.</SectionHead>
        <WrapText>
            <Text>
                We do more for our clients than just staying relevant to the times: you and your
                closings genuinely matter to us. The vast majority of real estate firms spread
                themselves too thin, providing a vast scope of services in order to cherry-pick as
                many convenient clients as possible.
            </Text>
            <Text>
                We at HT Law have instead narrowed our focus to specialize in real estate
                transactions exclusively.
            </Text>
        </WrapText>
        <BgImg src={require('./bg.svg')} alt="" />
    </ProvideSectionStyled>
);

export default ProvideSection;
