import React from 'react';
import {
    FooterNavStyled,
    FooterNavSection,
    FooterNavSectionHead,
    FooterNavSectionBody,
    FooterNavLink
} from './styles';

class FooterNavMobile extends React.Component {
    state = {
        opensItem: []
    };
    toggleItem = index => event => {
        const { opensItem } = this.state;
        const currentIndex = opensItem.findIndex(item => item === index);

        if (currentIndex === -1) {
            this.setState({ opensItem: [index] });
        } else {
            opensItem.splice(currentIndex, 1);
            this.setState({ opensItem: [...opensItem] });
        }
    };

    render() {
        const { opensItem } = this.state;
        const { navigationData } = this.props;
        const { toggleItem } = this;
        return (
            <FooterNavStyled>
                {Object.keys(navigationData).map((sectionName, indexSection) => (
                    <FooterNavSection key={indexSection}>
                        <FooterNavSectionHead onClick={toggleItem(indexSection)}>
                            {sectionName.charAt(0).toUpperCase() + sectionName.substr(1)}
                        </FooterNavSectionHead>
                        <FooterNavSectionBody open={opensItem.includes(indexSection)}>
                            {navigationData[sectionName].map((navItem, indexNavItem) => (
                                <FooterNavLink to={navItem.link} key={indexNavItem}>
                                    {navItem.name}
                                </FooterNavLink>
                            ))}
                        </FooterNavSectionBody>
                    </FooterNavSection>
                ))}
            </FooterNavStyled>
        );
    }
}

export default FooterNavMobile;
