import styled from 'styled-components';

export const Container = styled.div`
    .auth-forgot-password {
        width: 600px;
    }
    .auth-forgot-password form {
        margin: 4rem 0 3rem 0;
    }
    .auth-forgot-password form .button {
        width: 100%;
    }
    .auth-forgot-password form .text-error {
        margin-bottom: 2rem;
    }
    .text-link-blue {
        color: #349fff;
        font-weight: 600;
        text-decoration: none;
        white-space: nowrap;
    }
    @media (min-width: 1659px) {
        .auth-forgot-password {
            width: 900px;
        }
        .auth-forgot-password form .button {
            width: 360px;
        }
    }
    @media (max-width: 1023px) {
        .auth-forgot-password {
            font-size: 16px;
        }
    }
    @media (max-width: 767px) {
        .auth-forgot-password {
            width: 100%;
        }
    }
`;

export const Title = styled.h1`
    color: #349fff;
    font-size: 40px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
    }
`;
