import styled from 'styled-components';
import { colors } from 'styleguide/variables';

export const InputContainer = styled.div`
    position: relative;
    margin-bottom: 20px;
    display: inline-block;
    min-width: 280px;
    max-width: 100%;

    input {
        z-index: 10;
        box-sizing: border-box;
        border: 1px solid ${colors.gray};
        outline: none;
        width: 100%;
        font-size: 18px;
        padding: 20px 10px;
        height: 60px;

        &:focus {
            border-color: ${colors.black};
        }

        &:-webkit-autofill {
            animation-name: onAutoFillStart;
        }

        &:not(:-webkit-autofill) {
            animation-name: onAutoFillCancel;
        }

        @keyframes onAutoFillStart {
        }

        @keyframes onAutoFillCancel {
        }
    }

    .success-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        fill: ${colors.green};
    }

    &.disabled {
        input {
            color: #545454;
            background-color: ${colors.pinkLight};
            pointer-events: none;
        }
    }

    &.error {
        input {
            border-color: ${colors.pinkRed};
        }
        label {
            color: ${colors.pinkRed};
        }
        .hint {
            margin-top: 36px;
        }
    }

    &.block {
        display: block;
    }
`;
