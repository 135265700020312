import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../Header/logo.svg';

export const LogoStyles = styled(LogoSVG)`
    max-width: 120px;
    filter: grayscale(100%);
`;

export const FooterLogoLink = styled(RouterNavLink)`
    ${LogoStyles} {
        width: 335px;
        margin: 5px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-bottom: 15px;
        ${LogoStyles} {
            width: 240px;
            margin: 0;
        }
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        ${LogoStyles} {
            width: 190px;
        }
    }
`;
export const FooterNavItem = styled(RouterNavLink)`
    display: inline-block;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    font-size: 20px;
    color: #989898;
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
        opacity: 0.7;
    }

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 16px;
    }
`;
export const FooterTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const FooterNav = styled.div`
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: none;
    }
    ${FooterNavItem} {
        margin-left: 70px;
    }
`;
export const FooterCopyRight = styled.div`
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    color: #989898;
    font-size: 15px;
    font-weight: 700;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        text-align: center;
        font-size: 10px;
    }
`;
export const FooterLinksItem = styled(RouterNavLink)`
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    color: #989898;
    font-size: 15px;
    text-decoration: none;
    transition: opacity 0.4s;

    &:hover {
        opacity: 0.7;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 10px;
    }
`;
export const FooterLinks = styled.div`
    display: flex;
    ${FooterLinksItem} {
        margin-right: 50px;
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            margin-right: 25px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
`;
export const FooterBottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        flex-direction: column-reverse;
    }
    ${FooterLinks} {
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            margin-bottom: 15px;
        }
    }
    ${FooterCopyRight} {
        margin-right: 50px;
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            margin-right: 0;
        }
    }
`;

export const FooterStyled = styled.footer`
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
    ${FooterTop} {
        margin-bottom: 60px;
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            margin-bottom: 20px;
        };
    };
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding: 30px 35px;
        border-top: 1px solid rgba(217, 222, 225, 1);
    };
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 30px 20px;
        border-top: 1px solid rgba(217, 222, 225, 1);
    };
`;
