import React from 'react';
import clsx from 'clsx';

import * as icons from './icons';

const Icon = ({ name, className, ...rest }) => {
    const icon = icons[name];
    const iconProps = {
        test: 'AAAAAAAA',
        className: clsx('icon', className),
        ...rest
    };

    return React.cloneElement(icon, iconProps);
};

export default React.memo(Icon);
