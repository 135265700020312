import React from 'react';

export const arrow = (
    <svg width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1.84646V0.615719C0 0.378186 0.1115 0.161576 0.286 0.0594246C0.460501 -0.0427267 0.667001 -0.0113428 0.817 0.139423L6 5.35899L11.1835 0.139423C11.333 -0.0113428 11.5395 -0.0421113 11.7145 0.0594246C11.8895 0.160961 12 0.378186 12 0.615719V1.84646C12 2.03107 11.9325 2.20583 11.8165 2.32275L6.3165 7.86108C6.132 8.04631 5.8675 8.04631 5.683 7.86108L0.183 2.32275C0.0674992 2.20583 0 2.03107 0 1.84646Z" />
    </svg>
);

export const cancel = (
    <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.7734 4.50064L8.73842 1.53462C8.90609 1.36492 9 1.1368 9 0.899163C9 0.661528 8.90609 0.433354 8.73842 0.263648C8.65518 0.18012 8.55607 0.113803 8.44681 0.0685441C8.33756 0.0232853 8.22033 0 8.10194 0C7.98354 0 7.86633 0.0232853 7.75707 0.0685441C7.64782 0.113803 7.54871 0.18012 7.46547 0.263648L4.49955 3.22961L1.53453 0.263648C1.45129 0.18012 1.35218 0.113803 1.24293 0.0685441C1.13367 0.0232853 1.01646 0 0.898063 0C0.779668 0 0.662454 0.0232853 0.553201 0.0685441C0.443949 0.113803 0.344834 0.18012 0.261597 0.263648C0.0939206 0.433354 0 0.661528 0 0.899163C0 1.1368 0.0939206 1.36492 0.261597 1.53462L3.2266 4.50064L0.261597 7.46571C0.0939206 7.63542 0 7.86359 0 8.10122C0 8.33886 0.0939206 8.56703 0.261597 8.73674C0.344896 8.82016 0.444027 8.88638 0.553271 8.93157C0.662515 8.97676 0.779703 9 0.898063 9C1.01642 9 1.13361 8.97676 1.24286 8.93157C1.3521 8.88638 1.45123 8.82016 1.53453 8.73674L4.49955 5.77073L7.46547 8.73674C7.54877 8.82016 7.6479 8.88638 7.75714 8.93157C7.86639 8.97676 7.98358 9 8.10194 9C8.2203 9 8.33749 8.97676 8.44673 8.93157C8.55597 8.88638 8.65512 8.82016 8.73842 8.73674C8.90609 8.56703 9 8.33886 9 8.10122C9 7.86359 8.90609 7.63542 8.73842 7.46571L5.7734 4.50064Z" />
    </svg>
);

export const check = (
    <svg width="20" height="20" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6666 0.874666L12.4107 0.116452C12.0632 -0.0925434 11.5872 -0.0123472 11.3546 0.293854L5.1985 8.35717L2.36942 5.84437C2.07393 5.58191 1.59238 5.58191 1.29689 5.84437L0.22162 6.79943C-0.0738733 7.06189 -0.0738733 7.4896 0.22162 7.75449L4.57194 11.6185C4.81545 11.8347 5.1985 12 5.54324 12C5.88798 12 6.23546 11.808 6.45981 11.5188L13.869 1.81028C14.1043 1.50408 14.014 1.08366 13.6666 0.874666Z" />
    </svg>
);

export const login = (
    <svg viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="path" stroke="#121212" stroke-width="1.2">
                <g id="Group" transform="translate(10.000000, 6.000000)">
                    <g id="Page-1" transform="translate(0.000000, 3.000000)">
                        <path d="M9.81253782,4.07135605 L0.714285714,4.07135605" id="Stroke-1" />
                        <polyline
                            id="Stroke-3"
                            points="6.36245393 0.543188148 9.49093496 4.0987437 6.36245393 7.65429926"
                        />
                    </g>
                    <polyline id="Path-10" points="8 0 14 0 14 13.6875 8 13.6875" />
                </g>
            </g>
        </g>
    </svg>
);

export const minus = (
    <svg width="10" height="2" viewBox="0 0 10 2" xmlns="http://www.w3.org/2000/svg">
        <rect width="10" height="2" rx="1" />
    </svg>
);

export const plus = (
    <svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.03516 9.00006V15.0001C7.03516 15.2653 7.14053 15.5196 7.32806 15.7072C7.5156 15.8947 7.76994 16.0001 8.03516 16.0001C8.30037 16.0001 8.55471 15.8947 8.74225 15.7072C8.92978 15.5196 9.03516 15.2653 9.03516 15.0001V9.00006H15.0352C15.3004 9.00006 15.5547 8.89469 15.7422 8.70715C15.9298 8.51962 16.0352 8.26528 16.0352 8.00006C16.0352 7.73484 15.9298 7.4805 15.7422 7.29297C15.5547 7.10543 15.3004 7.00006 15.0352 7.00006H9.03516V1.00006C9.03516 0.734845 8.92978 0.480505 8.74225 0.292969C8.55471 0.105432 8.30037 6.10352e-05 8.03516 6.10352e-05C7.76994 6.10352e-05 7.5156 0.105432 7.32806 0.292969C7.14053 0.480505 7.03516 0.734845 7.03516 1.00006V7.00006H1.03516C0.76994 7.00006 0.5156 7.10543 0.328064 7.29297C0.140528 7.4805 0.0351562 7.73484 0.0351562 8.00006C0.0351562 8.26528 0.140528 8.51962 0.328064 8.70715C0.5156 8.89469 0.76994 9.00006 1.03516 9.00006H7.03516Z" />
    </svg>
);

export const plusMini = (
    <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 4H6V1C6 0.734784 5.89464 0.480444 5.70711 0.292908C5.51957 0.105371 5.26522 0 5 0C4.73478 0 4.48043 0.105371 4.29289 0.292908C4.10536 0.480444 4 0.734784 4 1V4H1C0.734784 4 0.480429 4.10537 0.292892 4.29291C0.105356 4.48044 0 4.73478 0 5C0 5.26522 0.105356 5.51956 0.292892 5.70709C0.480429 5.89463 0.734784 6 1 6H4V9C4 9.26522 4.10536 9.51956 4.29289 9.70709C4.48043 9.89463 4.73478 10 5 10C5.26522 10 5.51957 9.89463 5.70711 9.70709C5.89464 9.51956 6 9.26522 6 9V6H9C9.26522 6 9.51957 5.89463 9.70711 5.70709C9.89464 5.51956 10 5.26522 10 5C10 4.73478 9.89464 4.48044 9.70711 4.29291C9.51957 4.10537 9.26522 4 9 4Z" />
    </svg>
);

export const search = (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.641 14.293L10.841 9.47098C11.6089 8.3794 11.988 7.0615 11.9173 5.72874C11.8467 4.39597 11.3305 3.12554 10.4514 2.12125C9.57243 1.11697 8.38158 0.43707 7.06991 0.190528C5.75824 -0.0560138 4.40178 0.145112 3.21811 0.761695C2.03443 1.37828 1.09215 2.37457 0.542416 3.59074C-0.00732231 4.8069 -0.132684 6.17245 0.18649 7.46836C0.505663 8.76427 1.25086 9.91541 2.30252 10.7372C3.35418 11.5589 4.65136 12.0036 5.98599 12C7.22514 11.9997 8.43284 11.6096 9.43802 10.885L14.238 15.7069C14.3301 15.7998 14.4397 15.8735 14.5605 15.9238C14.6812 15.9741 14.8107 16 14.9415 16C15.0723 16 15.2018 15.9741 15.3225 15.9238C15.4433 15.8735 15.5529 15.7998 15.645 15.7069C15.8319 15.5189 15.9365 15.2643 15.9358 14.9991C15.935 14.734 15.829 14.48 15.641 14.293ZM5.98599 9.99998C5.19394 10.0043 4.41843 9.77345 3.75775 9.33659C3.09706 8.89972 2.58096 8.27651 2.27484 7.546C1.96871 6.81549 1.88636 6.01056 2.0382 5.23319C2.19005 4.45582 2.56925 3.74103 3.12777 3.17942C3.6863 2.61781 4.39898 2.23466 5.1755 2.07853C5.95202 1.9224 6.75743 2.00031 7.48962 2.30241C8.22181 2.6045 8.84782 3.11716 9.28833 3.77543C9.72883 4.43369 9.96397 5.20792 9.96399 5.99998C9.96543 6.52382 9.86371 7.0428 9.66458 7.52732C9.46545 8.01184 9.17282 8.45239 8.80343 8.82383C8.43404 9.19526 7.9951 9.49031 7.51168 9.69211C7.02827 9.89392 6.50983 9.99853 5.98599 9.99998Z" />
    </svg>
);

export const menu = (
    <svg width="4" height="16" viewBox="0 0 4 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.99999 4.00024C1.60443 4.00024 1.21774 3.88297 0.888844 3.66321C0.559946 3.44345 0.303586 3.13108 0.152211 2.76562C0.000835463 2.40017 -0.0387293 1.99801 0.0384412 1.61005C0.115612 1.22208 0.306099 0.865765 0.585804 0.58606C0.86551 0.306355 1.22183 0.115867 1.60979 0.0386963C1.99775 -0.038475 2.39992 0.001091 2.76537 0.152466C3.13082 0.303841 3.44319 0.560201 3.66295 0.889099C3.88272 1.218 3.99999 1.60468 3.99999 2.00024C3.99999 2.53068 3.78925 3.03936 3.41417 3.41443C3.0391 3.7895 2.53042 4.00024 1.99999 4.00024ZM1.99999 10.0002C1.60443 10.0002 1.21774 9.88297 0.888844 9.66321C0.559946 9.44345 0.303586 9.13108 0.152211 8.76562C0.000835463 8.40017 -0.0387293 7.99801 0.0384412 7.61005C0.115612 7.22208 0.306099 6.86576 0.585804 6.58606C0.86551 6.30635 1.22183 6.11587 1.60979 6.0387C1.99775 5.96153 2.39992 6.00109 2.76537 6.15247C3.13082 6.30384 3.44319 6.5602 3.66295 6.8891C3.88272 7.218 3.99999 7.60468 3.99999 8.00024C3.99999 8.53068 3.78925 9.03936 3.41417 9.41443C3.0391 9.7895 2.53042 10.0002 1.99999 10.0002ZM1.99999 16.0002C1.60443 16.0002 1.21774 15.883 0.888844 15.6632C0.559946 15.4434 0.303586 15.1311 0.152211 14.7656C0.000835463 14.4002 -0.0387293 13.998 0.0384412 13.61C0.115612 13.2221 0.306099 12.8658 0.585804 12.5861C0.86551 12.3064 1.22183 12.1159 1.60979 12.0387C1.99775 11.9615 2.39992 12.0011 2.76537 12.1525C3.13082 12.3038 3.44319 12.5602 3.66295 12.8891C3.88272 13.218 3.99999 13.6047 3.99999 14.0002C3.99999 14.5307 3.78925 15.0394 3.41417 15.4144C3.0391 15.7895 2.53042 16.0002 1.99999 16.0002Z" />
    </svg>
);
