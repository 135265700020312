import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 20px;
    margin-top: 170px;
    margin-bottom: 100px;
    flex-direction: row-reverse;
    box-sizing: border-box;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        margin-top: 41px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-top: 60px;
    }
    @media (max-width: 1440px) {
        margin-top: 54px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        margin-top: 0;
    }
    @media (max-width: 480px) {
        margin-top: 32px;
        flex-direction: column-reverse;
        align-items: center;
    }
`;
export const TextBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    height: 680px;
    width: 35%;
    @media (max-width: 480px) {
        height: 250px;
        width: 100%;
        border-right: 0;
        justify-content: flex-start;
        padding-left: 36px;
    }
    @media (max-width: 480px) {
        padding-left: 29px;
    }
    border-right: 1px solid #e4e4e4;
`;
export const TextInnerBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
export const ImgBlock = styled.div`
    display: flex;
    height: 680px;
    width: 65%;
    @media (max-width: 480px) {
        height: 100%;
        width: 100%;
    }
    justify-content: center;
    align-items: center;
`;
export const ContactInfo = styled.p`
    color: #707070;
    font-family: ff-dagny-web-pro, sans-serif;
    font-style: normal;
    font-weight: 300;
`;
export const Head = styled.h1`
    color: #3ee4cd;
    font-family: ff-dagny-web-pro, sans-serif;
    font-style: normal;
    font-size: 36px;
    font-weight: 400;
`;
export const BgImg = styled.img`
    display: block;
    width: 883px;
    height: 671px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 883px;
        height: 671px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 750px;
        height: 569px;
    }
    @media (max-width: 1440px) {
        width: 676px;
        height: 514px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 550px;
        height: 418px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 456px;
        height: 347px;
    }
    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
    }
`;
