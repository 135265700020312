import styled from 'styled-components';

export const PriceSectionStyled = styled.section`
    margin-top: 40px;
    padding: 20px;
    border-bottom: 1px solid #eceff0;
    text-align: center;
    position: relative;
    box-sizing: border-box;
`;

export const SubHead = styled.p`
    margin-top: 25px;
    text-align: center;
    color: ${props => props.theme.colors.dark};
    font-size: 28px;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    font-weight: 300;

    span {
        color: ${props => props.theme.colors.primary};
    }

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-top: 15px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-top: 10px;
    }
`;
export const Price = styled.p`
    margin: 0;
    margin-bottom: 70px;
    line-height: 1.2;
    text-align: center;
    font-size: 100px;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    color: ${props => props.theme.colors.black};
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        margin-bottom: 60px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-top: 10px;
        margin-bottom: 36px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 50px;
    }
    & span {
        display: none;
        @media (max-width: ${props => props.theme.media.tablet}px) {
            display: initial;
        }
        @media (max-width: ${props => props.theme.media.laptop}px) {
            display: initial;
        }
    }
`;

export const BgImg = styled.img`
    width: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-top: 40px;
    }
`;

export const Description = styled.p`
    margin-bottom: 50px;
    color: #404040;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    font-style: normal;
    font-weight: 300;
    display: none;
    @media (max-width: ${props => props.theme.media.laptop}px) {
        display: block;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: none;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: block;
    }
`;
