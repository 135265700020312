import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { ReactComponent as LogoSVG } from './logo.svg';
 
const widthNavPhone = '320px';

export const LogoPicture = styled(LogoSVG)`
    margin-top: -16px;
    margin-left: -10px;
    height: 70px
`;

export const LogoLink = styled(RouterNavLink)`
    font-family: cursive;
    font-size: 40px;
    text-decoration: none;
    height: 40px;
    line-height: 25px;
    color: #707070;

    &.active {
        font-weight: bold;
        color: #404040;
    }

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 43px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 25px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 18px;
    }
`;

export const BgMenu = styled.div`
    display: none;

    @media (max-width: ${props => props.theme.media.laptop}px) {
        display: ${p => (p.active ? 'block' : 'none')};
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

export const BtnMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 18px;
`;

export const BtnMenuLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey};
`;

export const BtnLink = styled(RouterNavLink)`
    padding: 8px 28px;
    text-align: center;
    font-size: 18px;
    font-family: ff-dagny-web-pro, sans-serif;
    color: #fff;
    background-color: #3ee4cd;
    border-radius: 7px;
    box-shadow: 0px 3px 6px #50ffe7;
    border: none;
    cursor: pointer;
    transition: opacity 0.4s;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }

    &.active {
        font-weight: bold;
    }

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding: 7px 24px;
        font-size: 14px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        padding: 0;
        font-size: 15px;
        color: #fff;
        background: none;
        box-shadow: none;
        opacity: ${props => (props.active ? '.6' : '1')};
        &.active {
            opacity: 0.6;
            font-weight: 400;
            color: ${props => props.theme.colors.grey};
        }
        &:hover {
            opacity: 1;
        }
    }
`;

export const NavLink = styled(RouterNavLink)`
    display: inline-block;
    color: ${props => props.theme.colors.grey};
    font-weight: 300;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    font-size: 22px;
    cursor: pointer;
    transition: opacity 0.4s;
    text-decoration: none;

    &.active {
        font-weight: bold;
        color: ${p => p.theme.colors.dark};
    }

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 18px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        font-size: 15px;
        color: #fff;
        opacity: ${props => (props.active ? '.6' : '1')};

        &.active {
            font-weight: bold;
            color: #fff;
        }

        &:hover {
            opacity: 1;
        }
    }
`;
export const Nav = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    transition: all 0.4s ease-out;

    ${NavLink} {
        margin-right: 55px;

        &.showMobile {
            display: none;
        }

        @media (max-width: ${props => props.theme.media.fullHD}px) {
            margin-right: 40px;
        }
        @media (max-width: ${props => props.theme.media.laptop}px) {
            &.showMobile {
                display: inline-block;
            }
            margin-bottom: 25px;
        }
    }

    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: ${widthNavPhone};
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 65px 25px;
        position: fixed;
        top: 0;
        left: ${props => (props.active ? '0px' : '-' + widthNavPhone)};
        bottom: 0;
        background-color: ${props => props.theme.colors.dark};
        z-index: 999;
    }
`;

export const BtnCloseLine = styled.div`
    width: 15px;
    height: 2px;
    background-color: #fff;
    position: absolute;
`;

export const BtnClose = styled.div`
    display: none;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 30px;
    right: 25px;
    @media (max-width: ${props => props.theme.media.laptop}px) {
        display: block;
    }
    ${BtnCloseLine} {
        &:first-child {
            transform: rotateZ(45deg);
            top: 6px;
            left: 0;
        }
        &:last-child {
            transform: rotateZ(-45deg);
            top: 6px;
            right: 0;
        }
    }
`;

export const HeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 35px 110px;
    background-color: #fff;
    z-index: 998;
    box-sizing: border-box;
    max-width: ${props => props.theme.media.laptopL}px;
    margin: auto;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding: 25px 95px;
    }

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding: 16px 25px 12px 25px;
    }

    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 100%;
        height: 78px;
        justify-content: center;
        align-items: center;
        border-bottom: none;
        box-shadow: 0px 3px 6px rgba(170, 170, 170, 0.16);
    }

    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 0 80px 0 80px;
    }

    ${BtnMenu} {
        display: none;

        @media (max-width: ${props => props.theme.media.laptop}px) {
            display: flex;
            position: absolute;
            top: 50%;
            left: 27px;
            transform: translateY(-50%);
        }
    }
`;
